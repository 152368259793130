import React from 'react';
import Layout from '../components/layouts/Layout';
import RequestComponent from '../components/Requests';

const Requests: React.FC = () => {
    return (
        <Layout>
            <RequestComponent />
        </Layout>
    );
};

export default Requests;
