import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from "react-hook-form";
import { Button } from '@material-ui/core';
import CustomInput from './CustomInput';
import { modularScale } from '../util/modularScale';
import { Link } from 'gatsby';
import { gradient, grey, greyLight } from '../util/theme';
import Spinner from './Spinner';
import axios, { handleError } from '../util/axios';
import { Alert } from 'antd';
import { mdiCheckCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';

const Container = styled.div`
	background: #fff;
	padding: 1rem;
	border-radius: 4px;
	
	@media (min-width: 450px) {
	    padding: 4rem;
	}
	
	@media (min-width: 900px) {
	    padding: 4rem;
	}
`;

const InputError = styled.span`
	color: red;
    font-size: ${modularScale(-1)};
`;

const Input = styled(CustomInput)`
	margin-bottom: 1rem;
`;

const Title = styled.h2`
	font-size: ${modularScale(1)};
	font-weight: bold;
	margin: 1rem 0 1rem;
	line-height: 1;
	
	@media (min-width: 900px) {
        margin: 0 0 1rem;
	}
`;

const Info = styled.div`
	margin-bottom: 1.5rem;
`;

const StyledButton = styled(Button)`
    color: #fff !important;
	background-color: #000;
	height: 42px;
	
	&.Mui-disabled {
	    background-color: #acacac;
	    
            &:hover, &:focus, &:active {
            background-color: #acacac;
        }
	}
	
	&:hover, &:focus, &:active {
	    background-color: #000;
	}
	
	svg {
	    width: 24px;
	}
`;

const ButtonText = styled.div`
	display: flex;
	align-items: center;
	
	svg {
	    width: 18px;
	    margin-left: 0.5rem;
	}
`;

const StyledAlert = styled(Alert)`
	margin-bottom: 1rem;
`;

const Disclaimer = styled.p`
	font-size: 12px;
	color: ${grey};
	margin-top: 1rem;
`;

const RequestForm: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [requestError, setRequestError] = useState('');
    const { register, handleSubmit, errors } = useForm();

    const onSubmit = async data => {
        setIsLoading(true);
        setIsDisabled(true);

        try {
            await axios.post('/request', data);
            setIsSuccess(true);
        } catch (err) {
            handleError(err, () => {
                setRequestError('An error occurred while submitting this request. Please try again later.');
            })
        }

        setIsLoading(false);
    }

    const getButtonText = () => {
        if (isSuccess) {
            return <ButtonText>Request sent <Icon path={mdiCheckCircleOutline} /></ButtonText>
        }

        if (isLoading) {
            return <Spinner color={'#fff'} />
        }

        return 'Make Request';
    }

    return (
        <Container>
            { requestError && <StyledAlert message={requestError} type="error"/> }

            <Title>Make a request!</Title>

            <Info>I love playing what <em>you</em> want to hear! Please share what you'd like to see on the channel, and be
            sure to let me know how to find it.</Info>

            <form onSubmit={handleSubmit(onSubmit)}>
                {errors.title && <InputError>This field is required.</InputError>}
                <Input name="title" label="Title" type="text" size="small" inputRef={register({ required: true })}/>
                <Input name="artist" label="Artist (if applicable)" type="text" size="small" inputRef={register}/>
                <Input name="context" label="Movie, TV series, Composer, etc." type="text" size="small" inputRef={register}/>
                <Input name="comments" label="Comments (include a link if possible)" type="text" size="small" multiline rows={5} inputRef={register}/>

                <StyledButton variant="contained" fullWidth type="submit" size="large" disableElevation disabled={isDisabled}>
                    {getButtonText()}
                </StyledButton>

                <Disclaimer>Note: Song request fulfillment is at the discretion of the performing artist and heavily weighted towards popular demand. Every request is considered.</Disclaimer>
            </form>
        </Container>
    );
};

export default RequestForm;
